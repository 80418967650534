all<template>
  <b-card title="Career">
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Add Career</span>
        </template>
        <AddCareer />
      </b-tab>

      <b-tab @click="$router.push('/all-career')">
        <template #title>
          <feather-icon icon="HomeIcon" data-testid="all-careers"/>
          <span>All Career</span>
        </template>
        <router-view />
        <!-- <AllArticales></AllArticales> -->
      </b-tab>

    </b-tabs>

  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BTabs, BTab, BCardText, BCard,
} from 'bootstrap-vue'


import AddCareer from './AddCareer.vue'

export default {
  components: {
    BCardCode,
    BCard,
    BCardText,
    BTabs,
    BTab,
    AddCareer,
  },
  data() {
    return {

    }
  },
}
</script>
